import './App.css';
import "./style/Fonts.css"
import Home from "./components/Home";

function App() {
  return (
  <>
    <Home/>
  </>
    
  );
}

export default App;
